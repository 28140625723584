<template>
    <div class="container">
        <b-row class="mt-40">
            <b-col
                cols="12"
                class="callback"
            >
                <img
                    v-for="index in 3"
                    :key="index"
                    :class="'question-' + index"
                    src="/images/question.svg"
                    alt="login"
                >

                <h1>{{ $t('page.callback.verifying') }}</h1>
                <h4>{{ $t('page.callback.wait') }}</h4>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    export default {
        name: 'LoginCallback',
        computed: {
            ...mapState('auth', [
                'inputErrors',
            ]),
        },
        methods: {
            ...mapMutations('user', ['setGeneralData']),
        },
        async created() {
            try {
                await this.$store.dispatch(
                    'auth/loginWithGoogle',
                    this.$route.query.code
                );
                if (Object.keys(this.inputErrors).length == 0) {
                    this.$toast.open({
                        message: this.$t('notification.loggedIn'),
                        duration: 4000,
                        position: 'top-right',
                        type: 'success',
                    });
                    this.setGeneralData();
                }

                const storedData = localStorage.getItem('loginRedirect');
                if (storedData) {
                    const routeData = JSON.parse(storedData);

                    this.$router
                        .push({
                            path: routeData.path,
                            params: { ...routeData.params },
                            query: { ...routeData.query },
                        })
                        .catch(() => {
                            //
                        });

                    localStorage.removeItem('loginRedirect');
                } else {
                    this.$router.push({ name: 'Home' }).catch(() => {
                        //
                    });
                }
            } catch (error) {
                //
            }
        },
    };
</script>
<style scoped lang="scss">
.callback {
  min-height: calc(100vh - 72px - 392px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  @include media-breakpoint-down(sm) {
    min-height: 50vh;
  }
  img {
    position: absolute;
    opacity: 0.036;
    width: 25%;
    @include media-breakpoint-down(sm) {
      width: calc(100% / 3);
    }
    &.question-1 {
      left: 0;
    }
    &.question-2 {
      top: -40px;
      @include media-breakpoint-down(md) {
        top: -30%;
      }
      @include media-breakpoint-down(sm) {
        top: -15%;
      }
    }
    &.question-3 {
      bottom: -40px;
      right: 0;
      @include media-breakpoint-down(md) {
        bottom: -30%;
      }
      @include media-breakpoint-down(sm) {
        bottom: -15%;
      }
    }
  }
  h1 {
    letter-spacing: 1px;
    color: $primary;
    font-size: 180px;
    margin-bottom: 0;
    @include media-breakpoint-between(sm, md) {
      font-size: 120px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 60px;
    }
  }
  h4 {
    text-align: center;
    font-size: 48px;
    color: $secondary;
    @include media-breakpoint-between(sm, md) {
      font-size: 40px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }
}
</style>
